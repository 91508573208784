<template>
    <div>
        <DxToolbar :height="56" class="toolbar">
            <DxItem location="before" locate-in-menu="never">
                <template #default>
                    <router :breadcrumbsItems="breadcrumbsItems" />
                </template>
            </DxItem>
            <DxItem location="after">
                <template #default>
                    <search-vue
                        :readOnly="true"
                        id="textbox_NhatKyTruyenTaiBieuDo_Timkiem"
                    />
                </template>
            </DxItem>
        </DxToolbar>

        <bieu-do-vue
            :NgayGioHeThong="NgayGioHeThong"
            :data_from_api="data_from_api"
            :DataBieuDo="DataBieuDo"
            @ThayDoiSoGTVT="ThayDoiSoGTVT"
            @getBieuDo="getBieuDo"
        />

        <!-- <LoaddingVue :Model="Loadding" /> -->
    </div>
</template>

<script>
import router from "@/components/_Common/Components/router";
import LoaddingVue from "@/components/_Common/Components/Loadding.vue";
import SearchVue from "@/components/_Common/Components/Search.vue";
import BieuDoVue from "@/components/BaoCaoThongKe/NhatKyTruyenTai/BieuDo.vue";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";

export default {
    // Permission: $PermText("BieuDo", "BCNhatKyTruyenTai"),
    layout: "application",
    components: {
        router,
        BieuDoVue,
        SearchVue,
        LoaddingVue,
        DxToolbar,
        DxItem,
    },
    data() {
        return {
            url_DsTinhSo: this.$i18n.t("url.DungChungDanhSachSo"),
            url_DsBenTheoSo: this.$i18n.t("url.DungChungDanhSachBenTheoSo"),
            url_BieuDoTanSuat: this.$i18n.t("url.BieuDoTanSuatNhatKyTruyenTai"),
            url_BieuDoTGianVaDuLieuTruyenTaiTrongNgay: this.$i18n.t(
                "url.BieuDoTGianTruyenTaiTrungBinhTrongNgayNhatKyTruyenTai"
            ),
            url_NgayGioHeThong: this.$i18n.t("url.NgayGioHeThong"),
            Loadding: false,
            data_from_api: {
                DsTinhSo: [],
                DsBenTheoSo: [],
                load: 0,
                IdSo: null,
                IdBenXe: null,
            },
            DataBieuDo: null,
            NgayGioHeThong: null,
            breadcrumbsItems: [
                {
                    id: "breadcrums_NhatKyTruyenTaiBieuDo_BaoCaoThongKe",
                    text: "Báo cáo - thống kê",
                    disabled: true,
                    to: "",
                },
                {
                    id: "breadcrums_NhatKyTruyenTaiBieuDo_NhatKyTruyenTaiBieuDo",
                    text: "Nhật ký truyền tải",
                    disabled: true,
                    to: "",
                },
            ],
        };
    },
    methods: {
        async getData(IdSo) {
            this.$startLoading;

            // Lấy giờ hệ thống
            let NgayGioHeThong = await this.$Core.Api.BaoCaoBenXe(
                this.url_NgayGioHeThong
            ).Get();
            this.NgayGioHeThong = NgayGioHeThong.Data.Data;

            // Lấy ds Tỉnh sở
            let DsTinhSo = await this.$Core.Api.BaoCaoBenXe(
                this.url_DsTinhSo
            ).Get();
            this.data_from_api.DsTinhSo = DsTinhSo.Data.Data || [];
            if (this.data_from_api.DsTinhSo[0]?.IdSo == 0) {
                this.data_from_api.DsTinhSo.splice(0, 1);
            }
            if (this.data_from_api.load == 0) {
                if (this.LoaiTaiKhoan == this.$i18n.t("string.so")) {
                    this.ThayDoiSoGTVT(
                        this.$Core.Auth.TokenParsed.CauHinhCu.MaSoDonVi
                    );
                } else {
                    if (!!IdSo) {
                        this.ThayDoiSoGTVT(IdSo);
                    } else {
                        this.ThayDoiSoGTVT(
                            this.data_from_api.DsTinhSo[0]?.IdSo
                        );
                    }
                }
            }
            this.$stopLoading;
        },
        async ThayDoiSoGTVT(id) {
            this.$startLoading;
            // Lấy ds bến theo Tỉnh sở
            if (this.data_from_api.load > 0) {
                this.data_from_api.IdSo = null;
                this.data_from_api.IdBenXe = null;
            }
            let DsBenTheoSo = await this.$Core.Api.BaoCaoBenXe(
                this.url_DsBenTheoSo,
                {
                    idSos: id,
                }
            ).Get();
            this.data_from_api.DsBenTheoSo = DsBenTheoSo.Data.Data || [];
            if (this.data_from_api.DsBenTheoSo[0]?.IdSo == 0) {
                this.data_from_api.DsBenTheoSo.splice(0, 1);
            }
            this.data_from_api.load++;
            this.$stopLoading;
        },
        async getBieuDo(data, TenBieuDo) {
            this.$startLoading;
            this.DataBieuDo = null;
            let DataBieuDo = null;
            // Lấy ds bến theo Tỉnh sở
            if (TenBieuDo == "BDTanSuatTruyenTaiDL") {
                DataBieuDo = await this.$Core.Api.BaoCaoBenXe(
                    this.url_BieuDoTanSuat,
                    data
                ).Get();
            } else if (TenBieuDo == "BDTGianVaDuLieuTruyenTaiTrongNgay") {
                DataBieuDo = await this.$Core.Api.BaoCaoBenXe(
                    this.url_BieuDoTGianVaDuLieuTruyenTaiTrongNgay,
                    data
                ).Get();
            }
            this.DataBieuDo = DataBieuDo.Data.Data;
            // this.DataBieuDo = {
            //     Data: {
            //         lst_BieuDo_NhatKyTruyenTaiModel: [
            //             {
            //                 Title: "01/06",
            //                 KeHoach: 20,
            //                 TangCuong: 10,
            //                 CoDinh: 14,
            //             },
            //             {
            //                 Title: "02/06",
            //                 KeHoach: 20,
            //                 TangCuong: 13,
            //                 CoDinh: 23,
            //             },
            //             {
            //                 Title: "03/06",
            //                 KeHoach: 20,
            //                 TangCuong: 0,
            //                 CoDinh: 16,
            //             },
            //             {
            //                 Title: "04/06",
            //                 KeHoach: 20,
            //                 TangCuong: 2,
            //                 CoDinh: 22,
            //             },
            //             {
            //                 Title: "05/06",
            //                 KeHoach: 20,
            //                 TangCuong: 6,
            //                 CoDinh: 19,
            //             }
            //         ],
            //         lst_ColName: [
            //             {
            //                 Id: "KeHoach",
            //                 Name: "Kế hoạch",
            //                 Stack: "1",
            //                 Show: true
            //             },
            //             {
            //                 Id: "TangCuong",
            //                 Name: "Tăng cường",
            //                 Stack: "2",
            //                 Show: true
            //             },
            //             {
            //                 Id: "CoDinh",
            //                 Name: "Cố định",
            //                 Stack: "2",
            //                 Show: true
            //             }
            //         ]
            //     }
            // };
            this.$stopLoading;
        },
    },
    created() {
        this.data_from_api.IdSo = localStorage.getItem("IdSo");
        this.data_from_api.IdBenXe = localStorage.getItem("IdBenXe");
        if (!!this.data_from_api.IdSo) {
            this.getData(this.data_from_api.IdSo);
        } else {
            this.getData();
        }
        localStorage.removeItem("IdSo");
        localStorage.removeItem("IdBenXe");
    },
};
</script>